import { forwardRef, useEffect, useState } from 'react';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import { Dialog, Slide, Stack, Box, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useResponsive } from 'hooks/use-responsive';
import { setFullScreen } from 'utils/set-screen';

import MobileImage from 'assets/images/mobile.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MobileDialogView = () => {
    const { isMobile } = useResponsive();

    const [status, setStatus] = useState<boolean>(false);

    useEffect(() => {
        if (isDeviceMobile && isMobile) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [isDeviceMobile, isMobile]);

    const closeModal = () => {
        setStatus(false);
        setFullScreen();
    };

    return (
        <Dialog
            open={status}
            TransitionComponent={Transition}
            maxWidth="sm"
            fullWidth={true}
            sx={{
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(8px)"
                },
                '& .MuiDialog-paper': {
                    height: "80%",
                    backgroundColor: "unset",
                    boxShadow: "none"
                }
            }}
        >
            <Stack direction={isMobile ? "column" : "row"} sx={{ height: "100%" }}>
                <Stack height={isMobile ? "50%" : "100%"} width={isMobile ? "100%" : "50%"}>
                    <Box
                        component="img"
                        src={MobileImage}
                        height={1}
                    />
                </Stack>

                <Stack justifyContent="center" alignItems="center" gap={1}>
                    <Typography variant="h6" color="white" textAlign="center">Please rotate your screen</Typography>
                </Stack>
            </Stack>
        </Dialog>
    );
};

export default MobileDialogView;