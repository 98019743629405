import { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
    AppBar, Box, Toolbar, Typography, Stack
} from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { set } from 'store/reducers/modal';
import { turnOn } from 'store/reducers/audio';
import { fCurrency } from 'utils/format-number';
import Iconify from 'components/iconify/iconify';
import Logo from 'components/logo';
import SettingDialogView from 'sections/setting';

import BackgroundAudio from 'components/audio/background';
import ButtonClickAudio, { ButtonClickAudioRef } from 'components/audio/button-click';

import TopRightBackground from 'assets/images/top-right-bg.png';
import SettingButton from 'assets/images/buttons/setting.png';
import PasswordButton from 'assets/images/buttons/password.png';
import ButtonBackground from 'assets/images/buttons/button-background.png';
import VolumnOn from 'assets/images/buttons/volumn-on.png';
import VolumnOff from 'assets/images/buttons/volumn-off.png';

function Header() {
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const { volume } = useSelector((state) => state.audio);

    const buttonClickAudioRef = useRef<ButtonClickAudioRef>(null);

    const [settingModalStatus, setSettingModalStatus] = useState(false);

    const openAuthModal = (value: string) => {
        dispatch(set(value));
    };

    const openSettingModal = () => {
        setSettingModalStatus(true);
    };

    const handleChangeVolumn = () => {
        dispatch(turnOn(volume > 0 ? 0 : 1));
    };

    return (
        <>
            <AppBar position="fixed" sx={{ background: "none", boxShadow: "none", height: { xs: "70px" } }}>
                <Toolbar disableGutters sx={{ justifyContent: { xs: "center", sm: "space-between" }, alignItems: "flex-start" }}>
                    <Stack
                        direction="row"
                        justifyContent="start" alignItems="center"
                        sx={isMobile ? { height: "50px", width: "50%", cursor: "pointer" } : { height: { xs: "50px", sm: "100px" }, width: "50%", cursor: "pointer" }}
                    >
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            height={1}
                            sx={isMobile ?
                                { width: "50px", backgroundImage: `url(${ButtonBackground})`, backgroundSize: "100% 100%", "&:hover": { filter: "brightness(150%)" } }
                                :
                                { width: { xs: "50px", sm: "100px" }, backgroundImage: `url(${ButtonBackground})`, backgroundSize: "100% 100%", "&:hover": { filter: "brightness(150%)" } }
                            }
                            onClick={handleChangeVolumn}
                        >
                            <Box
                                component="img"
                                src={volume > 0 ? VolumnOn : VolumnOff}
                                sx={isMobile ?
                                    { height: "25px", width: "25px" }
                                    :
                                    { height: { xs: "25px", sm: "auto" }, width: { xs: "25px", sm: "auto" } }
                                }
                            />
                        </Stack>
                        <Box
                            component="img"
                            src={SettingButton}
                            height={1}
                            sx={{ "&:hover": { filter: "brightness(150%)" } }}
                            onClick={openSettingModal}
                        />
                        <Box
                            component="img"
                            src={PasswordButton}
                            height={1}
                            sx={{ "&:hover": { filter: "brightness(150%)" } }}
                            onClick={() => isLoggedIn && openAuthModal("password-change")}
                        />
                    </Stack>

                    <Logo
                        sx={isMobile ?
                            { height: "100%", width: "100%", marginTop: "0px", justifyContent: "center" }
                            :
                            { height: { xs: "50px", sm: "100%" }, width: { xs: "100%", sm: "100%" }, marginTop: { xs: "15px", sm: "0px" }, justifyContent: "center" }
                        }
                    />

                    <Stack justifyContent="end" alignItems="end" sx={{ width: "50%" }}>
                        <Stack
                            justifyContent="center" alignItems="center"
                            sx={isMobile ?
                                { backgroundImage: `url(${TopRightBackground})`, backgroundSize: "100% 100%", width: "150px", height: "50px", cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                                :
                                { backgroundImage: `url(${TopRightBackground})`, backgroundSize: "100% 100%", width: { xs: "150px", sm: "300px" }, height: { xs: "50px", sm: "100px" }, cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                            }
                            onClick={() => !isLoggedIn && openAuthModal("login")}
                        >
                            {isLoggedIn ?
                                <Stack
                                    direction="column"
                                    sx={isMobile ?
                                        { width: "110px", marginLeft: "20px" }
                                        :
                                        { width: "200px", marginLeft: "30px" }
                                    }
                                >
                                    <Stack direction="row" justifyContent="space-between" width={1}>
                                        <Typography variant={isMobile ? "body2" : "h6"} fontWeight={700} fontSize={isMobile ? "14px" : "20px"}>Balance</Typography>
                                        <Typography variant={isMobile ? "body2" : "h6"} fontWeight={700} fontSize={isMobile ? "14px" : "20px"}>{fCurrency(user.balance || 0)}</Typography>
                                    </Stack>

                                    <Stack direction="row" justifyContent="space-between" width={1}>
                                        <Typography variant={isMobile ? "body2" : "body1"} fontWeight={700} fontSize={isMobile ? "14px" : "20px"} color="yellow" sx={{ fontStyle: "italic" }}>Bonus</Typography>
                                        <Typography variant={isMobile ? "body2" : "body1"} fontWeight={700} fontSize={isMobile ? "14px" : "20px"} color="yellow">{fCurrency((user.bonus || 0) + (user.matchBonus || 0))}</Typography>
                                    </Stack>
                                </Stack>
                                :
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={2}
                                    sx={isMobile ?
                                        { width: "200px", marginLeft: "20px" }
                                        :
                                        { width: "200px", marginLeft: { xs: "20px", sm: "50px" } }
                                    }
                                >
                                    <Typography
                                        textTransform="uppercase"
                                        sx={isMobile ?
                                            { fontSize: "20px" }
                                            :
                                            { fontSize: { xs: "20px", sm: "34px" } }
                                        }
                                    >Login</Typography>
                                    <Iconify
                                        icon="basil:login-solid"
                                        sx={isMobile ?
                                            { height: "30px", width: "30px" }
                                            :
                                            { height: { xs: "30px", sm: "40px" }, width: { xs: "30px", sm: "40px" } }
                                        }
                                    />
                                </Stack>}
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>

            <SettingDialogView status={settingModalStatus} onClose={() => setSettingModalStatus(false)} />

            <BackgroundAudio />
            <ButtonClickAudio ref={buttonClickAudioRef} />
        </>
    );
};

export default Header;
